<template>
  <div>
    <div class="e-breadcrumb">套餐提成配置管理</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div class="e-handle-box" style="margin-bottom: 50px">
            <div class="handle-box">
              <span class="v-form-label">套餐名称:</span>
              <el-input placeholder="套餐名称" size="small" style="width:265px" v-model="packageName" clearable
                        class="handle-input mr10"></el-input>
              <div style="float: left">
                <el-row type="flex" justify="end">
                  <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                  </el-button>
                </el-row>
              </div>
            </div>
          </div>
          <div class="e-table-wrapper">
            <div class="item-head">
              <span class="th-head" style="width: 14%;">套餐名称</span>
              <span class="th-head" style="width: 14%">销售提成</span>
              <span class="th-head" style="width: 21%">第一工位</span>
              <span class="th-head" style="width: 23%">第二工位</span>
              <span class="th-head" style="width: 23%">销售工位</span>
            </div>
            <div class="item-body"  v-for="row in list">
              <div style="margin-bottom: 10px">
                <span class="th-l" style="width: 14%;">{{row.packageName}}</span>
               <div class="item-bt" v-if="!row.commissionType">
                 <span class="item-bt-sp" @click="addPackageCommission(row)">新增</span>
               </div>
                <div class="item-bt" v-else>
                  <span style="margin-right: 26px"v-if="row.commissionType==0">{{row.commissionValue}} %</span>
                  <span style="margin-right: 26px" v-if="row.commissionType==1">{{row.commissionValue}} 元/个</span>
                  <span class="item-bt-sp" @click="packageRemoveEvent(row)">删除</span>
                  <span class="item-bt-sp"  @click="packageEditEvent(row)" >修改</span>
                </div>
              </div>
            <div style="padding-left: 18px;">
              <div :class="index%2==0?'item-cono':'item-cont'" v-for="(item,index) in row.packageItemList">
                <span class="th-l" style="width: 28%;">{{item.itemName}}<span style="margin-left: 18px">{{item.itemNum+'/次'}}</span></span>
                <div class="th-l"  style="width: 21%">
                  <div  v-for="pc in item.onePaiCommission" style="margin-bottom: 5px">
                    <span class="ticheng">{{pc.gradeName}}</span>
                    <span class="ticheng"v-if="pc.unspecifiedType==0">非指定: {{pc.unspecifiedValue}} %</span>
                    <span class="ticheng" v-if="pc.unspecifiedType==1">非指定: {{pc.unspecifiedValue}} 元/个</span>
                    <span class="ticheng"v-if="pc.appointType==0">指定: {{pc.appointValue}} %</span>
                    <span class="ticheng" v-if="pc.appointType==1">指定: {{pc.appointValue}} 元/个</span>
                    <span class="btn" @click="removeEvent(pc.id)">删除</span>
                    <span class="btn" @click="editEvent(pc,item,1)">修改</span>
                  </div>
                  <span  class="xinzeng" @click="addPaiCommission(item,1)">新增</span>
                </div>
                <div class="th-l" style="width: 23%" >
                  <div v-for="pc in item.twoPaiCommission"  style="margin-bottom: 5px">
                    <span class="ticheng">{{pc.gradeName}}</span>
                    <span class="ticheng"v-if="pc.unspecifiedType==0">非指定: {{pc.unspecifiedValue}} %</span>
                    <span class="ticheng" v-if="pc.unspecifiedType==1">非指定: {{pc.unspecifiedValue}} 元/个</span>
                    <span class="ticheng"v-if="pc.appointType==0">指定: {{pc.appointValue}} %</span>
                    <span class="ticheng" v-if="pc.appointType==1">指定: {{pc.appointValue}} 元/个</span>
                    <span class="btn" @click="removeEvent(pc.id)">删除</span>
                    <span class="btn" @click="editEvent(pc,item,2)">修改</span>
                  </div>
                  <span   class="xinzeng" @click="addPaiCommission(item,2)">新增</span>
                </div>
                <div class="th-l" style="width: 23%" >
                  <div v-for="pc in item.threePaiCommission"  style="margin-bottom: 5px">
                    <span class="ticheng">{{pc.gradeName}}</span>
                    <span class="ticheng"v-if="pc.unspecifiedType==0">非指定: {{pc.unspecifiedValue}} %</span>
                    <span class="ticheng" v-if="pc.unspecifiedType==1">非指定: {{pc.unspecifiedValue}} 元/个</span>
                    <span class="ticheng"v-if="pc.appointType==0">指定: {{pc.appointValue}} %</span>
                    <span class="ticheng" v-if="pc.appointType==1">指定: {{pc.appointValue}} 元/个</span>
                    <span class="btn" @click="removeEvent(pc.id)">删除</span>
                    <span class="btn" @click="editEvent(pc,item,3)">修改</span>
                  </div>
                  <span  class="xinzeng" @click="addPaiCommission(item,3)">新增</span>
                </div>
              </div>
            </div>
            </div>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>

      <!--     新增套餐提成方案弹框-->
      <el-dialog title="新增提成方案" :visible.sync="addPackageVisible" width="50%" >
        <hr style="margin-top: -26px">
        <div class="dialog-left" style="margin-top: 8px">
          <span>套餐名称</span>
          <span style="margin-left: 18px;background-color: #C4C4C4;padding: 2px 8px 2px 8px">{{packageItem.packageName}}</span>
        </div>
        <div class="dialog-left">
          <span>提成设置</span>
          <div class="yeji">
            <div>
              <el-radio-group v-model="commissionType" style="margin-left: 8px">
                <el-radio :label="1">固定提成</el-radio>
                <el-radio :label="0">业绩比例</el-radio>
              </el-radio-group>
              <el-input v-model="commissionValue" onkeyup="value=value.replace(/[^(\d||/.)]/g,'')" placeholder="请输入内容" size="small" style="width: 128px;margin-left: 16px"></el-input>
              <span v-if="commissionType==1" style="font-size: 16px;margin-left: 6px">元 / 个</span>
              <span v-if="commissionType==0" style="font-size: 16px;margin-left: 6px">%</span>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="savePackageCommission">确 定</el-button>
            </span>
      </el-dialog>

      <!-- 编辑套餐提成方案弹框 -->
      <el-dialog title="新增提成方案" :visible.sync="editPackageVisible" width="50%" >
        <hr style="margin-top: -26px">
        <div class="dialog-left" style="margin-top: 8px">
          <span>套餐名称</span>
          <span style="margin-left: 18px;background-color: #C4C4C4;padding: 2px 8px 2px 8px">{{upName}}</span>
        </div>
        <div class="dialog-left">
          <span>提成设置</span>
          <div class="yeji">
            <div>
              <el-radio-group v-model="commissionType" style="margin-left: 8px">
                <el-radio :label="'1'">固定提成</el-radio>
                <el-radio :label="'0'">业绩比例</el-radio>
              </el-radio-group>
              <el-input v-model="commissionValue" onkeyup="value=value.replace(/[^(\d||/.)]/g,'')" placeholder="请输入内容" size="small" style="width: 128px;margin-left: 16px"></el-input>
              <span v-if="commissionType=='1'" style="font-size: 16px;margin-left: 6px">元 / 个</span>
              <span v-if="commissionType=='0'" style="font-size: 16px;margin-left: 6px">%</span>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="updatePackageCommission">确 定</el-button>
            </span>
      </el-dialog>

      <!-- 删除套餐提示框 -->
      <el-dialog title="提示" :visible.sync="delPackageVisible" width="300px" center>
        <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
        <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delPackageVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delPackageCommission">确 定</el-button>
            </span>
      </el-dialog>

      <!-- 新增提成方案弹框 -->
      <el-dialog title="新增提成方案" :visible.sync="addVisible" width="50%">
        <hr style="margin-top: -26px">
        <div class="dialog-left" style="margin-top: 8px">
          <span>套餐项目名称</span>
          <span style="margin-left: 18px;background-color: #C4C4C4;padding: 2px 8px 2px 8px">{{item.itemName}}</span>
        </div>
        <div class="dialog-left">
          <span>员工级别</span>
          <el-select v-model="categoryId" clearable placeholder="请选择" size="small" style="margin-left: 18px">
            <el-option
              v-for="item in userGradeList"
              :key="item.id"
              :label="item.gradeName"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="dialog-left">
          <span>提成设置</span>
          <div class="yeji">
            <div>
              <span class="yeji-span">非指定</span>
              <el-radio-group v-model="unspecifiedType">
                <el-radio :label="1">固定提成</el-radio>
                <el-radio :label="0">业绩比例</el-radio>
              </el-radio-group>
              <el-input v-model="unspecifiedValue" onkeyup="value=value.replace(/[^(\d||/.)]/g,'')" placeholder="请输入内容"
                        size="small" style="width: 128px;margin-left: 16px"></el-input>
              <span v-if="unspecifiedType==1" style="font-size: 16px;margin-left: 6px">元 / 个</span>
              <span v-if="unspecifiedType==0" style="font-size: 16px;margin-left: 6px">%</span>
            </div>
            <div>
              <span class="yeji-span">指定</span>
              <el-radio-group v-model="appointType">
                <el-radio :label="1">固定提成</el-radio>
                <el-radio :label="0">业绩比例</el-radio>
              </el-radio-group>
              <el-input v-model="appointValue" placeholder="请输入内容" onkeyup="value=value.replace(/[^(\d||/.)]/g,'')"
                        size="small" style="width: 128px;margin-left: 16px"></el-input>
              <span v-if="appointType==1" style="font-size: 16px;margin-left: 6px">元 / 个</span>
              <span v-if="appointType==0" style="font-size: 16px;margin-left: 6px">%</span>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="savePaiCommission">确 定</el-button>
            </span>
      </el-dialog>

      <!-- 编辑提成方案弹框 -->
      <el-dialog title="新增提成方案" :visible.sync="editVisible" width="50%">
        <hr style="margin-top: -26px">
        <div class="dialog-left" style="margin-top: 8px">
          <span>项目名称</span>
          <span style="margin-left: 18px;background-color: #C4C4C4;padding: 2px 8px 2px 8px">{{upItemName}}</span>
        </div>
        <div class="dialog-left">
          <span>员工级别</span>
          <el-select v-model="categoryId" disabled clearable placeholder="请选择" size="small" style="margin-left: 18px">
            <el-option
              v-for="item in userGradeList"
              :key="item.id"
              :label="item.gradeName"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="dialog-left">
          <span>提成设置</span>
          <div class="yeji">
            <div>
              <span class="yeji-span">非指定</span>
              <el-radio-group v-model="unspecifiedType">
                <el-radio :label="'1'">固定提成</el-radio>
                <el-radio :label="'0'">业绩比例</el-radio>
              </el-radio-group>
              <el-input v-model="unspecifiedValue" onkeyup="value=value.replace(/[^(\d||/.)]/g,'')"
                        placeholder="请输入内容" size="small" style="width: 128px;margin-left: 16px"></el-input>
              <span v-if="unspecifiedType=='1'" style="font-size: 16px;margin-left: 6px">元 / 个</span>
              <span v-if="unspecifiedType=='0'" style="font-size: 16px;margin-left: 6px">%</span>
            </div>
            <div>
              <span class="yeji-span">指定</span>
              <el-radio-group v-model="appointType">
                <el-radio :label="'1'">固定提成</el-radio>
                <el-radio :label="'0'">业绩比例</el-radio>
              </el-radio-group>
              <el-input v-model="appointValue" placeholder="请输入内容" onkeyup="value=value.replace(/[^(\d||/.)]/g,'')"
                        size="small" style="width: 128px;margin-left: 16px"></el-input>
              <span v-if="appointType=='1'" style="font-size: 16px;margin-left: 6px">元 / 个</span>
              <span v-if="appointType=='0'" style="font-size: 16px;margin-left: 6px">%</span>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="updatePaiCommission">确 定</el-button>
            </span>
      </el-dialog>

      <!-- 删除提示框 -->
      <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
        <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
        <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delPaiCommission">确 定</el-button>
            </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import {Base_url} from '../../config/index'

  export default {
    data() {
      return {
        base_url: '',

        packageName: '',
        item: '',

        userGradeList:[],
        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        addPackageVisible:false,
        packageItem: {},
        pcId:'',
        upName:'',
        commissionType:1,
        commissionValue:'',
        editPackageVisible:false,
        delPackageVisible:false,


        addVisible: false,
        unspecifiedType: 1,
        unspecifiedValue: '',
        appointType: 1,
        appointValue: '',
        categoryId:'',


        editVisible: false,
        editItem: {},
        upItemName: '',

        delVisible: false,
        paiId: '',

      }
    },
    async created() {
      this.searchEvent();
      this.base_url = Base_url
    },
    methods: {
      //获取工位分类
      async getUgCategoryList(type){
        let data = {
          station: type,
          categoryType:3,
          isDel:0
        }
        let res = await this.$get("/admin/getUgCategoryList", data)
        this.userGradeList=res.list
      },
      addPackageCommission(item){
        this.packageItem=item
      this.addPackageVisible=true
      },
      packageEditEvent(item) {
        this.pcId=item.id
        this.upName=item.packageName
        this.commissionType=item.commissionType
        this.commissionValue=item.commissionValue
        this.editPackageVisible = true;
      },
      packageRemoveEvent(item) {
        this.packageItem = item
        this.delPackageVisible = true;
      },
      // 新增套餐提成信息
     async savePackageCommission(){
       let data = {
         commissionType: this.commissionType,
         commissionValue: this.commissionValue,
         packageId: this.packageItem.pId,
       }
       let res = await this.$post("/admin/savePackageCommission", data)
       if (res.code == 200) {
         this.requestSearch()
         this.$message.success(res.message)
         this.addPackageVisible = false
       } else {
         this.$message.error(res.message)
       }
      },
      // 编辑套餐提成信息
      async updatePackageCommission() {
        let data = {
          id: this.pcId,
          commissionType: this.commissionType,
          commissionValue: this.commissionValue,
        }
        let res = await this.$post("/admin/savePackageCommission", data)
        if (res.code == 200) {
          this.requestSearch()
          this.$message.success(res.message)
          this.editPackageVisible = false
        } else {
          this.$message.error(res.message)
        }
      },

      async delPackageCommission() {
        let data = {
          id: this.packageItem.id,
        }
        let res = await this.$post("/admin/delPackageCommission", data)
        if (res.code == 200) {
          this.requestSearch()
          this.$message.success(res.message)
          this.delPackageVisible = false
        } else {
          this.$message.error(res.message)
        }
      },


      addPaiCommission(item,type) {
        this.categoryId=''
        this.getUgCategoryList(type)
        this.item = item
        this.station=type
        this.unspecifiedType=1
        this.appointType=1
        this.appointValue = 0
        this.unspecifiedValue = 0
        this.addVisible = true
      },

      editEvent(item,row,type) {
        this.categoryId=''
        this.getUgCategoryList(type)
        this.upItemName = row.itemName
        this.paiId=item.id
        this.unspecifiedType=item.unspecifiedType
        this.appointType=item.appointType
        this.appointValue = item.appointValue
        this.unspecifiedValue = item.unspecifiedValue
        this.categoryId = item.categoryId
        this.editVisible = true;
      },

      removeEvent(paiId) {
        this.paiId = paiId
        this.delVisible = true;
      },

      //保存套餐项目提配置
      async savePaiCommission() {
        let data = {
          station:this.station,
          unspecifiedType: this.unspecifiedType,
          unspecifiedValue: this.unspecifiedValue,
          appointType: this.appointType,
          appointValue: this.appointValue,
          itemId: this.item.itemId,
          packageId: this.item.packageId,
          categoryId:this.categoryId
        }
        let res = await this.$post("/admin/savePaiCommission", data)
        if (res.code == 200) {
          this.$message.success(res.message)
          this.requestSearch()
          this.addVisible = false
        } else {
          this.$message.error(res.message)
        }
      },

      //编辑套餐项目提配置
      async updatePaiCommission() {
        let data = {
          id: this.paiId,
          unspecifiedType: this.unspecifiedType,
          unspecifiedValue: this.unspecifiedValue,
          appointType: this.appointType,
          appointValue: this.appointValue,
        }
        let res = await this.$post("/admin/savePaiCommission", data)
        if (res.code == 200) {
          this.$message.success(res.message)
          this.requestSearch()
          this.editVisible = false
        } else {
          this.$message.error(res.message)
        }
      },

      // 删除套餐项目提成
      async delPaiCommission() {
        let data = {
          id: this.paiId,
        }
        let res = await this.$post("/admin/delPaiCommission", data)
        if (res.code == 200) {
          this.$message.success(res.message)
          this.requestSearch()
          this.delVisible = false
        } else {
          this.$message.error(res.message)
        }
      },

      handleCurrentChange(page) {
        this.requestSearch(page)
      },
      searchEvent() {
        this.requestSearch()
      },

      //分页获取项目提成配置
      async requestSearch(page) {
        let currentPage = page || 1;
        let data = {
          packageName: this.packageName,
          size: this.size == '' ? '10' : this.size,
          currentPage
        }
        let dataSet = await this.$get("/admin/getPackageCommissionList", data)
        let res = dataSet.dataSet
        let list = res.list;
        this.list = list;
        this.pagination = {
          count: res.count,
          pageNumber: res.pageNumber,
          pageSize: res.pageSize,
          pages: res.pages
        }
      },

    },
  }

</script>

<style scoped>
  .item-head{
    width: 100%;
    margin-bottom: 12px;
  }
  .item-body{
    padding: 8px 0px 18px 0px;
    width: 100%;
    background-color: #E5E5E5;
    margin-bottom: 18px;
  }
  .item-cono{
    width: 100%;
   background-color: #D4C9C9;
   margin-top: 5px;
   padding: 6px 0px 6px 0px;
  }
  .item-cont{
    width: 100%;
    background-color: #FAEDED;
    margin-top: 5px;
    padding: 6px 0px 6px 0px;
  }
  .th-head {
    text-align: center;
    padding-right: 10px;
    display: inline-block;
  }
  .th-l {
    font-size: 13px;
    text-align: center;
    padding-right: 10px;
    display: inline-block;
  }
  .item-bt{
    font-size: 13px;
    display: inline-block;
    width: 14%;
    text-align: center;
  }
  .item-bt-sp{
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    color: #1890ff;
  }

  .td-l {
    padding: 2px 12px;
    display: table-cell;
    text-align: center;
    /*vertical-align: middle;*/
  }

  .ticheng{
    margin-left: 8px;
    margin-right: 8px;
  }

  .xinzeng {
    margin: 0 auto;
    width: 38px;
    color: #1890ff;
    cursor: pointer;
  }
  .btn {
    margin: 0 auto;
    width: 38px;
    color: #1890ff;
    cursor: pointer;
    float: right;
  }

  .dialog-left {
    font-size: 16px;
    text-align: left;
  }

  .dialog-left span {
    line-height: 38px;
  }

  .yeji {
    margin-left: 38px;
    background-color: #FFDDDD;
    padding: 8px 8px 8px 8px;
    width: 88%;
  }

  .yeji-span {
    width: 66px;
    display: inline-block;
    text-align: right;
    margin-right: 16px;
  }

  .it-sp {
    margin-right: 6px;
    font-weight: bold;
    font-size: 10px;
  }
</style>
